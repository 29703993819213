.sidenav-trigger.show-on-medium-and-down {
    display: flex !important;
}

.sidenav {

    a {

        &.active {
            color: $primary-color;
        }
    }

    .brand {

        a {
            height: auto;
            font-weight: 700;
            font-size: $h5-fontsize;
            padding-top: ($sidenav-padding * 2);
            padding-bottom: ($sidenav-padding * 2);
            background-color: $secondary-color;
            color: #fff;

            @include hover-focus-active {
                background-color: $secondary-color;
                color: $primary-color;
            }
        }
    }

    .submenu {

        .collapsible-header {

            padding-left: ($sidenav-padding * 2);
            padding-right: ($sidenav-padding * 2);
        }

        .submenu-opener {
            color: $sidenav-font-color;
            font-size: $sidenav-font-size;
            font-weight: 500;
            line-height: $sidenav-line-height;
        }

        .collapsible-body {

            li a {
                padding: 0 (7.5px + $sidenav-padding) 0 ($sidenav-padding * 3);
            }
        }
    }
}
