.breadcrumbs {
    background-color: transparent;
    box-shadow: none;

    .breadcrumb {
        color: $off-black;

        &:before {
            color: $off-black;
        }
    }

    @media #{$medium-and-down} {
        display: none !important;
    }
}