.docChapter {
    margin-bottom: $spacer5;

    #switch-button-modified-rendered {
        .input-field {
            margin-top: 2.8rem;
        }

        .switch {
            //height: 45px;

            > label {
                display: flex;
                align-items: center;
            }

            & + label {
                top: -2.8rem;
            }

            span.offLabel,
            span.onLabel {
                display: inline-block;
            }
        }
    }

    .btn {
        margin-bottom: $spacer2;
        background-color: $secondary-color-default;
    }

    button {
        display: block;
    }

    .datepicker-container {

        .datepicker-cancel,
        .datepicker-clear,
        .datepicker-today,
        .datepicker-done {
            color: $secondary-color-default;
        }

        .datepicker-controls button {

            &:focus,
            &:active {
                background-color: $secondary-color-default;
            }
        }

        .datepicker-date-display {
            background-color: $datepicker-date-bg;
        }

        .datepicker-footer button {
            display: inline-block;
        }

        .datepicker-table {

            td {
                &.is-today {
                    color: $datepicker-selected;
                }

                &.is-selected {
                    background-color: $datepicker-selected;
                    color: #fff;
                }
            }
        }
    }

    .timepicker-container {

        .timepicker-tick.active,
        .timepicker-tick:hover {
            background-color: transparentize($secondary-color-default, .75);
        }

        .timepicker-canvas {

            line {
                stroke: $secondary-color-default;
            }
        }

        .timepicker-digital-display {
            background-color: $secondary-color-default;
        }

        .timepicker-canvas-bearing {
            fill: $secondary-color-default;
        }

        .timepicker-canvas-bg {
            fill: $secondary-color-default;
        }

        .timepicker-footer button {
            display: inline-block;
        }
    }

    .alert {

        &.success > .card-panel {
            background: lighten($success-color, 35%);
            color: darken($success-color, 15%);
        }

        &.error > .card-panel {
            background: lighten($error-color, 35%);
            color: darken($error-color, 15%);
        }
    }

    @media #{$medium-and-down} {

        .rendered {
            margin-top: 1rem;

            h3 {
                font-size: 1.55rem;
            }
        }
    }
}