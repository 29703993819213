$flowTextIntervals: 20;
$flowTextIntervalSize: $large-screen - $small-screen;
$flowTextBaseFontSize: 1.31rem;

.flow-text{
    font-weight: 300;
    $i: 0;
    @while $i <= $flowTextIntervals {
        @media only screen and (min-width : 360 + ($i * $flowTextIntervalSize)) {
            font-size: $flowTextBaseFontSize * (1 + (.02 * $i));
        }
        $i: $i + 1;
    }

    // Handle below 360px screen
    @media only screen and (max-width: 360px) {
        font-size: 1.2rem;
    }
}

.lead {

    .intro & {
        font-style: italic;
    }
}

html {
    //line-height: 1;
}

h1 {
    font-size: 2.5rem;
    margin-top: 0;
    color: $primary-color;
}
h2 {
    font-size: 2.0rem;
    margin-top: 0;
    border-bottom: 1px solid $primary-color;
    padding-bottom: 10px;
}
h3 {
    font-size: 1.75rem;
    margin-top: 0;
}
h4 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
    //font-weight: 300;
}

.h1 {
    @extend h1;
}
.h2 {
    @extend h2;
}
.h3 {
    @extend h3;
}
.h4 {
    @extend h4;
}
.h5 {
    @extend h5;
}

h4 + p.flow-text {
    margin-top: 10px;
}

.textMuted {
    color: color('grey', 'darken-2');
}

.extensionName {
    color: $primary-color;
}
