.prism {

    code[class*="language-"],
    pre[class*="language-"] {
        font-family: monospace !important;
        background: color('grey', 'lighten-3');

        * {
            font-family: monospace !important;
        }
    }

    div.prism-show-language {

        .prism-show-language-label {
            background: color('grey', 'darken-1');
            color: #fff;
            border-radius: 0;

            @media #{$medium-and-down} {
                display: none;
            }

        }
    }

    &.outdated {
        background: color('grey', 'lighten-2');

        * {
            background: color('grey', 'lighten-2') !important;
            color: color('grey', 'base') !important;
        }
    }

    &.prism-noLanguage {

        .prism-show-language {
            display: none;
        }
    }

    .token {

        .operator {
            background-color: unset;
        }
    }
}