main.content {
    padding-left: 0;
    padding-bottom: $spacer4;

    @media #{$large-and-up} {

        padding-left: $sidenav-width;
    }
}

.intro {
    padding-top: $gutter-width;
    padding-bottom: $gutter-width * 2;
}

.wideWrap {
    width: 100%;
}

footer.page-footer {
    color: color('brown', 'darken-2');

    @media #{$large-and-up} {

        padding-left: $sidenav-width;
    }

    .row {
        margin-bottom: 0;
    }

    h2 {
        border-bottom: none;
    }

    a {
        color: $off-black;

        @include hover-focus-active {
            color: $primary-color;
        }
    }
}